.address-photos-outer-container {
    border-radius: 10px;
    margin-bottom: 10px;
    margin-top: 20px;
}

.address-photos-header {
    font-size: x-large;
    text-align: center;
}

.address-photos-container {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    background-color: var(--gray-web);
    border-radius: 10px;
}

.address-photo-container {
    width: 45%;
    height: 400px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.address-photo-title {
    font-size: large;
    margin-top: 5px;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 95%;
    text-align: center;
}

.address-photo {
    /* border: 2px solid purple; */
    height: 100%;
    width: 100%;
    border-radius: 10px;
    object-fit: fill;
    overflow: hidden;
}

.photo {
    object-fit: fill;
    width: 100%;
    height: 100%
}
