.inspection-container {
    --morning-blue: rgb(183, 194, 193);
    --gunmetal: rgb(31, 36, 43);
    --light-gunmetal: rgb(49, 58, 70);
    --gray-web: #202124;
    --secondary: white; 
    --tertiary: #ffe5b4; 
    --space-cadet: #1a2d57ff;
    --celadon: #b2d3a8ff;
    --olivine: #b6d094ff;
    --barn-red: #6f1a07ff;
    --gunmetal: #292a2d;
    --light-gunmetal: rgb(49, 58, 70);
    --gray-web: #202124;
    --morning-blue: #b7c2c1;
    --saddle-brown: #874000ff;
    --pullman-brown-ups-brown: #5b3000ff;
    --black-bean: #301100ff;
    --success:#7DB9B6;
    --failure:#E96479;
    font-family: "Inter";
    scrollbar-color: var(--gunmetal) var(--light-gunmetal);
    --table-row-height: 40px;
    /* border: 2px solid rgb(25, 29, 34); */
    width: 100%;
    border-radius: 10px;
    /* box-shadow: 0 0 10px 1px rgb(25, 29, 34); */
    /* padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px; */
}

.inspection-header {
    display: flex;
    justify-content: center;
    font-size: xx-large;
    margin-top: 0px;
    margin-bottom: 0px;
}

.inspection-content-container {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    /* background-color: var(--gray-web); */
}

.inspection-category-container {
    /* border: 2px solid var(--light-gunmetal); */
    /* padding: 30px; */
    padding-top: 0px;
    border-radius: 10px;
    /* margin-bottom: 10px; */
}

.inspection-category-header {
    /* border-bottom: 4px solid var(--light-gunmetal); */
    /* padding-bottom: 10px; */
    font-size: x-large;
    text-align: center;
    color: var(--morning-blue);
}

.inspection-question-container {
    font-size: large;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    border-bottom: 1px solid var(--light-gunmetal);
    /* margin-bottom: 2px; */
    background-color: var(--gray-web);
    /* border-bottom: 1px solid var(--light-gunmetal) */
}

.inspection-question {
    margin-bottom: 5px;
    margin-top: 5px;
    font-size: 20px;
    color: rgb(235, 235, 235);
}

.inspection-question-answer-container {
    display: flex;
    align-items: center;
    padding-left: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.inspection-question-not-passing {
    border: 2px solid rgb(183, 194, 193);
    padding: 5px;
    border-radius: 5px;
    margin-right: 20px;
}

.inspection-question-not-passing:hover {
    border: 2px solid rgb(46, 122, 46);
    padding: 5px;
    border-radius: 5px;
    margin-right: 20px;
    cursor: pointer;
    color: rgb(46, 122, 46);
}

.inspection-question-passing {
    border: 2px solid var(--success);
    padding: 5px;
    border-radius: 5px;
    margin-right: 20px;
    color: var(--success);
}

.inspection-question-not-failed {
    border: 2px solid rgb(183, 194, 193);
    padding: 5px;
    border-radius: 5px;
    margin-right: 20px;
}

.inspection-question-not-failed:hover {
    border: 2px solid rgb(131, 36, 36);
    padding: 5px;
    border-radius: 5px;
    margin-right: 20px;
    color: rgb(131, 36, 36);
    cursor: pointer;
}

.inspection-question-failed {
    border: 2px solid var(--failure);
    padding: 5px;
    border-radius: 5px;
    margin-right: 20px;
    color: var(--failure)
}

.upload-inspection-image-modal-button-container {
    /* border: 3px solid red; */
    padding: 15px;
}

.upload-inspection-image-modal-button-container>button {
    padding: 15px;
    border-radius: 10px;
    border: none;
    box-shadow: none;
    font-size: large;
}

.upload-inspection-image-modal-button-container>button:hover {
    cursor: pointer;
}

.inspection-question-comment-input-container {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    font-size: large;
    --tertiary: #ffe5b4; 
    --gray-web: #202124;
    /* background-color: var(--tertiary); */
    color: var(--gray-web);
    border-radius: 10px;
    box-shadow: none;
}

.inspection-question-comment-input-label {
    margin-bottom: 5px;
    color: lightgray;
}

.inspection-question-comment-input {
    width: 95%;
    height: 100px;
    resize: none;
    border-radius: 10px;
    background-color: rgb(222, 227, 233);
    font-size: large;
    margin-bottom: 5px;
}

.inspection-question-comment-input-button {
    --tertiary: #ffe5b4; 
    --gray-web: #202124;
    background-color: var(--tertiary);
    color: var(--gray-web);
    border-radius: 10px;
    border: 1px solid var(--tertiary);
    padding: 15px;
    font-size: large;
    width: 95%;
}

.inspection-question-comment-input-button:hover {
    cursor: pointer;
}

.inspection-question-comment-container {
    /* border: 0.5px solid var(--light-gunmetal); */
    border-radius: 5px;
    display: flex;
    font-size: large;
    padding: 5px;
}

.inspection-question-comment-label {
    margin-right: 10px;
    font-size: large;
}

.inpsection-question-comment {
    font-size: large;
}

.submit-inspection-button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.submit-inspection-button {
    --tertiary: #ffe5b4; 
    --gray-web: #202124;
    background-color: var(--tertiary);
    color: var(--gray-web);
    border-radius: 10px;
    border: 1px solid var(--tertiary);
    padding: 15px;
    font-size: large;
    width: 40%;
}

.submit-inspection-button:hover {
    cursor: pointer;
}

.answer-errors-div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    width: 89%;
    color: rgb(255, 0, 0);
    font-size: large;
    margin-bottom: 10px;
    border: 2px solid red;
    border-radius: 10px;
    padding: 20px;
}

.inspection-question-outer-container {
    /* border: 2px solid var(--light-gunmetal); */
    background-color: var(--gray-web);
    border-radius: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
}

.inspection-question-left-container {
    /* border: 2px solid yellow; */
    width: 69%;
    box-sizing: border-box;
}

.inspection-question-right-container {
    /* border: 2px solid green; */
    width: 30%;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inspection-question-image-container {
    /* border: 2px solid purple; */
    border-radius: 10px;
    overflow: hidden;
    box-sizing: border-box;
    height: 150px;
    width: 200px;
    object-fit: fill;
}

.inspection-question-image {
    object-fit: fill;
    width: 100%;
    height: 100%;
}

.add-inspection-answer-image-outer-container {
    /* border: 3px solid red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: larger;
    margin-bottom: 20px;
}

.add-inspection-answer-image-title {
    /* border: 3px solid yellow; */
    text-align: center;
}

.upload-inspection-answer-image-select {
    /* border: 3px solid green; */
    padding: 15px;
    border-radius: 10px;
    font-size: large;
    --tertiary: #ffe5b4; 
    --gray-web: #202124;
    /* background-color: var(--tertiary); */
    color: var(--gray-web);
    border-radius: 10px;
    /* border: 1px solid var(--tertiary); */
    padding: 15px;
    font-size: large;
    box-shadow: none;
}

.upload-inspection-answer-image-select>input {
    padding: 15px;
    border-radius: 10px;
    border: none;
    box-shadow: none;
    font-size: large;
}

.upload-inspection-answer-image-upload {
    display: flex;
    justify-content: center;
}

.upload-inspection-answer-image-upload>button {
    --tertiary: #ffe5b4; 
    --gray-web: #202124;
    background-color: var(--tertiary);
    color: var(--gray-web);
    border-radius: 10px;
    border: 1px solid var(--tertiary);
    padding: 15px;
    font-size: large;
    width: 80%; 
    justify-content: center;
}

.upload-inspection-answer-image-upload>button:hover {
    cursor: pointer;
}