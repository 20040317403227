.inspection-list-container {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    background-color: var(--gray-web);
    height: fit-content;
}

.inspection-list-header-container {
    display: flex;
    justify-content: center;
}

.inspection-list-header {
    /* border: 3px solid  yellow; */
    color: var(--secondary);
}

.inspection-container-header {
    border-bottom: 1px solid var(--light-gunmetal);
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: large;
    padding-top: 10px;
    padding-bottom: 10px;
    --light-gunmetal: rgb(49, 58, 70)
}

.inspection-container-nav {
    border-bottom: 1px solid var(--gunmetal);
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    height: var(--table-row-height);
    background-color: var(--light-gunmetal)
}

.inspection-container-nav:hover {
    opacity: 0.75;
}

.id-container {
    box-sizing: border-box;
    width: 10%;
    display: flex;
    justify-content: center;
    border-right: 2px solid var(--gunmetal);
}

.address {
    width: 45%;
    display:flex;
    justify-content: center;
    text-align: center;
}

.inspection-date {
    width: 18%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    border-left: 2px solid var(--gunmetal);
    border-right: 2px solid var(--gunmetal);
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
}

.inspection-number {
    width: 10%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    border-right: 2px solid var(--gunmetal);
    text-align: center;
    padding-right: 10px
}

.status {
    width: 10%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    text-align: center;
}

.status-passed {
    width: 10%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    text-align: center;
    color: var(--success);
}

.status-failed {
    width: 10%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    text-align: center;
    color: var(--failure)
}

/* .add-inspection-outer {
    border: 3px solid red;
} */

.add-inspection-title {
    /* border: 3px solid blue; */
    text-align: center;
}

.address-select-outer {
    /* border: 3px solid yellow; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: large;
    margin-bottom: 20px;
}

.address-select-outer>select:hover {
    cursor: pointer;
}

.inspection-type-select {
    /* border: 3px solid purple; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: large;
    margin-bottom: 20px;
}

.inspection-type-select>select:hover {
    cursor: pointer;
}

.inspection-number-select {
    /* border: 3px solid orange; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: large;
    margin-bottom: 20px;
}

.inspection-number-select>select:hover {
    cursor: pointer;
}

.button-outer {
    /* border: 3px solid pink; */
    padding: 10px;
    display: flex;
    justify-content: space-around;
}

.button-outer>button:hover {
    cursor: pointer;
}

.generate-inspection-button {
    /* border: 3px solid blue; */
    --tertiary: #ffe5b4; 
    --gray-web: #202124;
    background-color: var(--tertiary);
    color: var(--gray-web);
    border-radius: 10px;
    border: 1px solid var(--tertiary);
    padding: 15px;
    font-size: large;
    width: 40%;
}

.cancel-button {
    /* border: 3px solid green; */
    --tertiary: #ffe5b4; 
    --gray-web: #202124;
    background-color: var(--tertiary);
    color: var(--gray-web);
    border-radius: 10px;
    border: 1px solid var(--tertiary);
    padding: 15px;
    font-size: large;
    width: 40%;
}

.add-inspection-list-modal-button {
    /* border: 3px solid red; */
    display: flex;
    justify-content: center;
    width: 100%;
}

.add-inspection-list-modal-button>button {
    /* border: 3px solid green; */
    --tertiary: #ffe5b4; 
    --gray-web: #202124;
    background-color: var(--tertiary);
    color: var(--gray-web);
    border-radius: 10px;
    border: 1px solid var(--tertiary);
    padding: 15px;
    font-size: large;
    width: 40%; 
}

.add-inspection-list-modal-button>button:hover {
    cursor: pointer;
}