.open-photo-modal-button {
    height: 100%;
    width: 100%;
    object-fit: fill;
    overflow: hidden;
}

.open-photo-modal-button:hover {
    cursor: pointer;
}

.modal-photo-preview {
    object-fit: fill;
    width: 100%;
    height: 100%
}

.modal-photo-outer-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.modal-photo-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: x-large;
    padding-bottom: 5px;
    text-align: center;
}

.inspection-modal-photo-title-container {
    display: flex;
    justify-content: right;
    align-items: center;
    padding-bottom: 5px;
}

.modal-photo-title {
    max-width: 80%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.close-modal-button {
    --tertiary: #ffe5b4; 
    --gray-web: #202124;
    background-color: var(--tertiary);
    color: var(--gray-web);
    border-radius: 10px;
    border: 1px solid var(--tertiary);
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: large;
}

.close-modal-button:hover {
    /* background-color: var(--light-gunmetal); */
    cursor: pointer;
}

.modal-photo-container {
    height: 100%;
    border-radius: 10px;
    object-fit: fill;
    overflow: auto;
}

.modal-photo {
    object-fit: fill;
    width: 100%;
}

.delete-image-button>button {
    --tertiary: #ffe5b4; 
    --gray-web: #202124;
    background-color: var(--tertiary);
    color: var(--gray-web);
    border-radius: 10px;
    border: 1px solid var(--tertiary);
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: large;
}

.delete-image-button>button:hover {
    /* background-color: var(--light-gunmetal); */
    cursor: pointer;
}
