
.address-list-container {
    background-color: var(--gray-web);
    width: 100%;
    border-radius: 10px;
    background-color: var(--gray-web);
    height: fit-content;
    overflow: hidden;
}

.address-modal-button-container>button {
    background-color: var(--tertiary);
    font-size: large;
    border: 1px solid var(--tertiary);
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: none;
    border-radius: 15px;
}

.address-modal-button-container>button:hover {
    cursor: pointer;
}

.address-list-header-container {
    display: flex;
    justify-content: center;
}

.address-list-header {
    /* border: 3px solid  yellow; */
    color: var(--secondary)
}

.address-container-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: large;
    padding-top: 10px;
    padding-bottom: 10px;
    --light-gunmetal: rgb(49, 58, 70)
}

.address-container {
    height: var(--table-row-height);
    border-bottom: 1px solid var(--gunmetal);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: var(--light-gunmetal)
}

.address-container:hover {
    opacity: 0.75;
}

.id-container {
    box-sizing: border-box;
    width: 20%;
    display: flex;
    justify-content: center;
    border-right: 2px solid var(--gunmetal)
}

.address {
    width: 60%;
    display:flex;
    justify-content: center;
    text-align: center;
}

.next-inspection-date {
    width: 20%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    border-left: 2px solid var(--gunmetal);
    text-align: center;
    padding-right: 5px;
    padding-left: 5px;
}

.add-address-modal-button {
    /* border: 3px solid red; */
    display: flex;
    justify-content: center;
}

.add-address-modal-button>button {
    --tertiary: #ffe5b4; 
    --gray-web: #202124;
    background-color: var(--tertiary);
    color: var(--gray-web);
    border-radius: 10px;
    border: 1px solid var(--tertiary);
    padding: 15px;
    font-size: large;
    width: 40%;
}

.add-address-modal-button>button:hover {
    cursor: pointer;
}
