
.address-inspection-container-nav {
    display: flex;
    flex-direction: column;
}


.address-inspection-container {
    /* border: 2px solid var(--light-gunmetal); */
    display: flex;
    justify-content: space-around;
    /* margin-bottom: 10px; */
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
    height: var(--table-row-height);
}

.address-id-container {
    box-sizing: border-box;
    width: 15%;
    display: flex;
    justify-content: center;
    border-right: 2px solid var(--light-gunmetal);
}

.address-inspection-date {
    width: 25%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    border-right: 2px solid var(--light-gunmetal);
    text-align: center;
    padding-right: 10px;
}

.address-inspection-number {
    width: 15%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    border-right: 2px solid var(--light-gunmetal);
    text-align: center;
}

.address-inspection-inspector {
    width: 30%;
    border-right: 2px solid var(--light-gunmetal);
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    text-align: center;
}

.address-inspection-status {
    width: 15%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    text-align: center;
}

.address-inspection-status-passed {
    width: 15%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    text-align: center;
    color: var(--success);
}

.address-inspection-status-failed {
    width: 15%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    text-align: center;
    color: var(--failure)
}

.address-inspection-answers-container {
}
