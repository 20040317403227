.outer-container {
    /* border: 3px solid red; */
    width: auto;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px
}

.inspection-type-container {
    /* border: 3px solid orange; */
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 20px;
    font-size: larger;
}

.inspection-type-container>select:hover {
    cursor: pointer;
}

.inspection-number-container {
    /* border: 3px solid brown; */
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: larger;
    margin-bottom: 15px;
}

.inspection-number-container>select:hover {
    cursor: pointer;
}

.inspection-button-container {
    /* border: 3px solid silver; */
    display: flex;
    justify-content: space-around;
}

.inspection-button-container>button:hover {
    cursor: pointer;
}

.generate-inspection-button {
    --tertiary: #ffe5b4; 
    --gray-web: #202124;
    background-color: var(--tertiary);
    font-size: large;
    border: 1px solid var(--tertiary);
    padding: 15px;
    box-shadow: none;
    border-radius: 10px;
    width: 40%;
}

.cancel-inspection-button {
    background-color: var(--tertiary);
    font-size: large;
    border: 1px solid var(--tertiary);
    padding: 15px;
    box-shadow: none;
    border-radius: 10px;
    width: 40%;
}
