
.single-address-container {
    --morning-blue: rgb(183, 194, 193);
    --gunmetal: rgb(31, 36, 43);
    --secondary: white; 
    --tertiary: #ffe5b4; 
    --space-cadet: #1a2d57ff;
    --celadon: #b2d3a8ff;
    --olivine: #b6d094ff;
    --barn-red: #6f1a07ff;
    --gunmetal: #292a2d;
    --light-gunmetal: rgb(49, 58, 70);
    --gray-web: #202124;
    --morning-blue: #b7c2c1;
    --saddle-brown: #874000ff;
    --pullman-brown-ups-brown: #5b3000ff;
    --black-bean: #301100ff;
    --success:#7DB9B6;
    --failure:#E96479;
    font-family: "Inter";
    scrollbar-color: var(--gunmetal) var(--light-gunmetal);
    --table-row-height: 40px;
    border-radius: 10px;
    background-color: var(--gray-web);
}

.single-address-header-container {
    /* border: 3px solid yellow; */
    display: flex;
    justify-content: center;
    color: var(--secondary);
    text-align: center;
}

.address-info-label-container {
    /* border: 3px solid green; */
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid var(--light-gunmetal);
    box-sizing: border-box;
}

.label-container {
    /* border: 3px solid blue; */
    color: var(--morning-blue);
    font-size: large;
    margin-right: 10px;
    display: flex;
    align-items: center;
}

.info-container {
    font-size: large;
    color: rgb(255, 255, 255);
    font-weight: 500;
}

.address-map-container-outer {
    width: 100%;
}

.address-map-container {
    width: 100%;
    border-radius: 10px;
    height: 50vh
}

.address-inspections-container {
    border-radius: 10px;
    overflow: hidden;
}
.address-inspection-container:hover {
    opacity: 0.75;
}

.address-inspections-header {
    font-size: x-large;
    text-align: center;
}

.address-inspection-container-header {
    padding: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: large;
    border-bottom: 1px solid var(--light-gunmetal)
}

.address-inspection-container-nav {
    border-bottom: 1px solid var(--gunmetal);
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: var(--light-gunmetal);
}

.address-inspection-container-nav:hover {
    opacity: 0.75;
}


.address-id-container {
    box-sizing: border-box;
    width: 15%;
    display: flex;
    justify-content: center;
    border-right: 2px solid var(--gunmetal);
}

.address-inspection-date {
    width: 25%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    border-right: 2px solid var(--gunmetal);
    text-align: center;
    padding-right: 10px;
}

.address-inspection-number {
    width: 15%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    border-right: 2px solid var(--gunmetal);
    text-align: center;
}

.address-inspection-inspector {
    width: 30%;
    border-right: 2px solid var(--gunmetal);
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    text-align: center;
}

.address-inspection-status {
    width: 15%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    text-align: center;
}

.address-inspection-status-passed {
    width: 15%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    text-align: center;
    color: var(--success);
}

.address-inspection-status-failed {
    width: 15%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    text-align: center;
    color: var(--failure)
}

.image-preview-container {
    height: 200px;
    width: 200px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    overflow: hidden;
}

.image-preview {
    width: 100%;
    height: 100%;
}

.address-responses-container{
    border-radius: 10px;
}

.address-responses-header {
    font-size: x-large;
    text-align: center;
    margin-top: 30px;
}

.address-response-container-header {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: large;
    background-color: var(--gray-web);
}

.address-response-container {
    border-bottom: 1px solid var(--gunmetal);
    background-color: var(--light-gunmetal);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
}

.address-response-top-container {
    /* border: 2px solid green; */
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 5px;
    border-bottom: 2px solid var(--light-gunmetal);
}

.address-response-id-container {
    box-sizing: border-box;
    width: 15%;
    display: flex;
    justify-content: center;
    border-right: 2px solid var(--light-gunmetal);
}


.address-response-date {
    width: 30%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    border-right: 2px solid var(--light-gunmetal);
    height: 100%;
    text-align: center;
    padding-right: 10px;
}

.address-response-dept {
    width: 25%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    border-right: 2px solid var(--light-gunmetal);
    height: 100%;
    text-align: center;
    padding-right: 10px;
}

.address-response-type {
    width: 20%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    height: 100%;
    text-align: center;
}


.address-response-bottom-container {
    /* border: 2px solid red; */
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 5px;
    height: 125px;
}

.address-response-units {
    /* border: 2px solid yellow; */
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    height: 100%;
    text-align: center;
    /* text-decoration: underline; */
}

.address-response-units-header {
    width: 100%;
    display: flex;
    justify-content: center;
    text-decoration: underline;
    margin-top: 5px;
}

.address-response-units-list {
    /* border: 2px solid brown; */
    box-sizing: border-box;
    width: 90%;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}


.address-response-description {
    /* border: 2px solid purple; */
    width: 65%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    height: 100%;
    text-align: center;
    margin-bottom: 10px;
}

.address-response-description-header {
    width: 100%;
    display: flex;
    justify-content: center;
    text-decoration: underline;
    margin-top: 5px;
}

.address-response-description-text {
    box-sizing: border-box;
    width: 90%;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.single-address-button-container {
    /* border: 3px solid red; */
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.update-address-modal-button>button {
    /* border: 3px solid blue; */
    --tertiary: #ffe5b4; 
    --gray-web: #202124;
    background-color: var(--tertiary);
    color: var(--gray-web);
    border-radius: 10px;
    border: 1px solid var(--tertiary);
    padding: 15px;
    font-size: large;
    width: 100%;
}

.update-address-modal-button>button:hover {
    cursor: pointer;
}

.update-address-modal-button {
    width: 26%;
}

.update-address-modal-button:hover {
    cursor: pointer;
}

.add-inspection-modal-button>button {
    /* border: 3px solid yellow; */
    --tertiary: #ffe5b4; 
    --gray-web: #202124;
    background-color: var(--tertiary);
    color: var(--gray-web);
    border-radius: 10px;
    border: 1px solid var(--tertiary);
    padding: 15px;
    font-size: large;
    width: 100%;
}

.add-inspection-modal-button>button:hover {
    cursor: pointer;
}

.add-inspection-modal-button {
    width: 26%
}

.add-inspection-modal-button>button:hover {
    cursor: pointer;
}

.add-images-plans-modal-button>button {
    /* border: 3px solid green; */
    --tertiary: #ffe5b4; 
    --gray-web: #202124;
    background-color: var(--tertiary);
    color: var(--gray-web);
    border-radius: 10px;
    border: 1px solid var(--tertiary);
    padding: 15px;
    font-size: large;
    width: 100%;
}

.add-images-plans-modal-button>button:hover {
    cursor: pointer;
}

.add-images-plans-modal-button {
    width: 26%;
}

.add-images-plans-modal-button:hover {
    cursor: pointer;
}

.update-address-save-button-container {
    /* border: 3px solid red; */
    display: flex;
    justify-content: center;
}

.update-address-save-button-container>button {
    --tertiary: #ffe5b4; 
    --gray-web: #202124;
    background-color: var(--tertiary);
    font-size: large;
    border: 1px solid var(--tertiary);
    padding: 15px;
    box-shadow: none;
    border-radius: 10px;
    width: 100%;
    margin-bottom: 15px;
}

.update-address-save-button-container>button:hover {
    cursor: pointer;
}

.upload-image-title-select-outer {
    /* border: 3px solid red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: larger;
    margin-bottom: 20px;
    /* margin-top: 10px; */
}

/* .upload-image-title-select-title {
    border: 3px solid yellow;
} */

.upload-image-title-select {
    /* border: 3px solid green; */
    padding: 15px;
    border-radius: 10px;
    font-size: large;
    --tertiary: #ffe5b4; 
    --gray-web: #202124;
    /* background-color: var(--tertiary); */
    color: var(--gray-web);
    border-radius: 10px;
    border: 1px solid var(--tertiary);
    padding: 15px;
    font-size: large;
    box-shadow: none;
}

.upload-image-select-button {
    /* border: 3px solid purple; */
    margin-bottom: 20px;
    font-size: large;
    padding: 10px;
}

.upload-image-select-button>input {
    padding: 15px;
    border-radius: 10px;
    border: none;
    box-shadow: none;
    font-size: large;
}

/* .upload-image-select-button:hover {
    cursor: pointer;
} */

/* .select-image-input-field:hover {
    cursor: pointer;
} */

.upload-image-upload-button>button {
    --tertiary: #ffe5b4; 
    --gray-web: #202124;
    background-color: var(--tertiary);
    color: var(--gray-web);
    border-radius: 10px;
    border: 1px solid var(--tertiary);
    padding: 15px;
    font-size: large;
    width: 100%; 
    margin-bottom: 15px;
}

.upload-image-upload-button>button:hover {
    cursor: pointer;
}