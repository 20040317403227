.splash-page-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.splash-page-header {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    font-size: 45px;
    font-weight: 600;
    color: var(--secondary);
}

.description-container {
    width: 80%;
    background-color: var(--light-gunmetal);
    border-radius: 10px;
    padding: 30px;
    font-size: large;
}

.button-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
    /* border: 3px solid red */
}

.signup-login-button {
    background-color: var(--tertiary);
    color: var(--gray-web);
    font-size: large;
    border: 1px solid var(--tertiary);
    padding-right: 20px;
    padding-left: 20px;
    padding-top:10px;
    padding-bottom:10px;
    margin: 10px;
    box-shadow: none;
    border-radius: 5px;
    text-decoration: none;
    align-self: center;
    text-align: center;
}


.signup-login-button:hover {
    cursor: pointer;
}

@keyframes borderLights {
    0% {
        border: 2px solid red;
    }
    20% {
        border: 2px solid blue
    }
    40% {
        border: 2px solid white;
    }
    60% {
        border: 2px solid red;
    }
    80% {
        border: 2px solid blue;
    }
    100% {
        border: 2px solid white;
    }
}
