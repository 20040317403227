/* TODO Add site wide styles */
* {
    --secondary: white; 
    --tertiary: #ffe5b4; 
    --space-cadet: #1a2d57ff;
    --celadon: #b2d3a8ff;
    --olivine: #b6d094ff;
    --barn-red: #6f1a07ff;
    --gunmetal: #292a2d;
    --light-gunmetal: rgb(49, 58, 70);
    --gray-web: #202124;
    --morning-blue: #b7c2c1;
    --saddle-brown: #874000ff;
    --pullman-brown-ups-brown: #5b3000ff;
    --black-bean: #301100ff;
    --success:#7DB9B6;
    --failure:#E96479;
    font-family: "Inter";
    scrollbar-color: var(--gunmetal) var(--light-gunmetal);
    --table-row-height: 40px;
}

.errors-div {
    /* display: flex;
    justify-content: center; */
    flex-direction: column;
    /* text-align: center; */
    color: var(--failure);
    font-size: large;
    margin-bottom: 10px;
    border: 2px solid --failure;
    border-radius: 10px;
    padding: 20px;
}

.placeholder {
    height: 80vh;
}

.mh75vh {
    min-height: 75vh;
    height: fit-content;
}

.mh70px {
    min-height: 70px;
}

.fxlar {
    font-size: x-large
}

.flarr {
    font-size: larger;
}

.flar {
    font-size: large;
    color: var(--gray-web);
}

.fmed {
    font-size: medium
}

.fbold {
    font-weight: bold;
}

.fxbold {
    font-weight: bolder;
}

.tdnone {
    text-decoration: none;
}

.tacen {
    text-align: center;
}

.tclight {
    color: lightgray
}

.iflight {
    background-color: rgb(180, 180, 180);
    color: var(--gunmetal);
    border-radius: 8px;
}

.iflight:focus {
    outline: none;
    border: 2px solid var(--gunmetal);
    background-color: rgb(196, 196, 196);;
}

.opt:checked {
    background-color: var(--gunmetal);
    color: var(--gray-web)
}

.btndark {
    background-color:var(--tertiary);
    border: 1px solid var(--tertiary);
    color: var(--gray-web);
    border-radius: 10px;
}

.btndark:hover {
    cursor: pointer;
}

.btnlight {
    background-color: lightgray;
    color: var(--gunmetal)
}

.btnlight:hover {
    cursor: pointer;
    background-color: rgb(150, 148, 148);
    color: var(--gunmetal)
}

.btnhvr:hover {
    cursor: pointer;
}

.btnlights:hover {
    cursor: pointer;
}

@keyframes borderLights {
    0% {
        border: 2px solid red;
    }
    20% {
        border: 2px solid blue
    }
    40% {
        border: 2px solid white;
    }
    60% {
        border: 2px solid red;
    }
    80% {
        border: 2px solid blue;
    }
    100% {
        border: 2px solid white;
    }
}

.nhvr:hover {
    color: rgb(170, 168, 168);
}

.lighthover:hover {
    color: rgb(170, 168, 168);
    /* text-decoration: underline; */
    /* animation: underlineLights .6s infinite; */
    /* animation-direction: alternate; */
}

@keyframes underlineLights {
    0% {
        border-bottom: 3px solid red;
    }
    20% {
        border-bottom: 3px solid blue
    }
    40% {
        border-bottom: 3px solid white;
    }
    60% {
        border-bottom: 3px solid red;
    }
    80% {
        border-bottom: 3px solid blue;
    }
    100% {
        border-bottom: 3px solid white;
    }
}

.modal-button-container>button {
    background-color: var(--tertiary);
    color: var(--gray-web);
    border-radius: 10px;
    border: 1px solid var(--tertiary);
    font-size: large;
    padding: 10px;
}

.modal-button-container>button:hover {
    cursor: pointer;
}

.ofyscroll {
    overflow-y: scroll;
}

.ofhidden {
    overflow: hidden;
}

.bnone {
    border: none;
}

.br10px {
    border-radius: 10px;
}

.bsbb {
    box-sizing: border-box;
}

.bred {
    border: 3px solid red;
}

.bgreen {
    border: 3px solid green
}

.byellow {
    border: 3px solid yellow
}

.darkbg {
    background-color: var(--gunmetal);
    color: var(--morning-blue);
}

.lighterbg {
    background-color: var(--gray-web);
    color: var(--morning-blue)
}

.sticky {
    display: sticky;
}

.flex {
    display: flex
}

.fdrow {
    display: flex;
    flex-direction: row;
}

.fdcol {
    display: flex;
    flex-direction: column;
    width: 100%
}

.aicen {
    display: flex;
    align-items: center;
}

.accen {
    display: flex;
    align-content: center;
}

.jccen {
    display: flex;
    justify-content: center;
}

.sb {
    display: flex;
    justify-content: space-between;
}

.sa {
    display: flex;
    justify-content: space-around;
}

.wauto {
    width: auto
}

.hauto {
    height: auto
}

.mw1000px {
    max-width: 1000px;
}

.w100vw {
    width: 100vw
}

.w60vw {
    width: 60vw
}

.w50vw {
    width: 50vw
}

.w40vw {
    width: 40vw
}

.w30vw {
    width: 30vw
}

.w100p {
    width: 100%
}

.w80p {
    width: 80%
}

.w60p {
    width: 60%
}

.w70p {
    width: 70%
}

.w50p {
    width: 50%
}

.wauto {
    width: auto;
}

.w100px {
    width: 100px;
}

.h80vh {
    height: 80vh;
}

.h70vh {
    height: 70vh;
}

.h100vh {
    height: 100vh
}

.h100p {
    height: 100%
}

.h80p {
    height: 80%
}

.h50p {
    height: 50%
}

.h20px {
    height: 20px
}

.h60px {
    height: 60px
}

.h50px {
    height: 50px
}

.h40px {
    height: 40px
}

.pad30 {
    padding: 30px;
}


.pad20 {
    padding: 20px;
}

.pad10 {
    padding: 10px;
}

.pad30lr {
    padding-left: 30px;
    padding-right: 30px;
}

.pad20r {
    padding-right: 20px
}

.pad20l {
    padding-left: 20px
}

.pad10r {
    padding-right: 10px
}

.pad10t {
    padding-top: 10px;
}

.pad10b {
    padding-bottom: 10px
}

.pad0 {
    padding: none;
}

.pad0t {
    padding: none;
}


.marlrauto {
    margin: 0 auto;
}

.mar10 {
    margin: 10px
}

.mar20 {
    margin: 20px
}

.mar30 {
    margin: 30px
}

.mar10t {
    margin-top: 10px
}

.mar30t {
    margin-top: 30px
}

.mar20t {
    margin-top: 20px
}

.mar10b {
    margin-bottom: 10px
}

.mar20b {
    margin-bottom: 20px
}

.mar10l {
    margin-left: 10px
}

.mar10r {
    margin-right: 10px
}
.mar5r {
    margin-right: 5px
}

.mar0 {
    margin: 0;
}


.tertiary-button>button {
    background-color: none;
    border: none;
    color: var(--tertiary);
    
}

.tertiary-button>button:hover {
    text-decoration: underline;
    font-weight: 200;
}

html {
    overflow: hidden;
    height: 100%;
}

body {
    overflow: auto;
    height: 100%;
}

h1 {
    color: var(--secondary);
}
