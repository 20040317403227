.splash-page-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.error-description-container {
    width: 80%;
    border: 2px solid rgb(25, 29, 34);
    border-radius: 15px;
    box-shadow: 0 0 10px 1px rgb(25, 29, 34);
    padding: 30px;
    font-size: larger;
    text-align: center;
}


@keyframes borderLights {
    0% {
        border: 2px solid red;
    }
    20% {
        border: 2px solid blue
    }
    40% {
        border: 2px solid white;
    }
    60% {
        border: 2px solid red;
    }
    80% {
        border: 2px solid blue;
    }
    100% {
        border: 2px solid white;
    }
}
